import { Button, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import CameraAlt from "@mui/icons-material/CameraAlt";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { authors } from "../../data";


const AddArticle = () => {
  const navigate = useNavigate();
  const [article, setArticle] = useState({
    images: []
  });
  const [loading, setLoading] = useState();
  const [previews, setPreviews] = useState([]);

  const handleEditorChange = (value) => {
    setArticle({ ...article, content: value });
  };

  const handleChange = (e) =>
    setArticle({ ...article, [e.target.name]: e.target.value });

  // Fonction pour importer et afficher les images
  const loadFile = (event) => {
    const files = event.target.files; // Obtenir les fichiers sélectionnés
    const imageArray = Array.from(files); // Convertir FileList en tableau
    const imagePreviews = []; // Pour stocker les aperçus

    imageArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        imagePreviews.push(reader.result); // Pousser les aperçus dans le tableau
        setArticle((prevArticle) => ({
          ...prevArticle,
          images: imageArray, // Stocker les fichiers d'images

        }));
        setPreviews(imagePreviews) // Mettre à jour les aperçus
      };
      reader.readAsDataURL(file); // Lire les fichiers comme DataURL pour l'aperçu
    });
  };
  const redirect = () => navigate("/articles");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const storage = getStorage();
    const colRef = collection(db, "articles");

    try {
      // Tableau pour stocker les promesses d'upload d'images
      const imageUploadPromises = article.images.map((image) => {
        return new Promise((resolve, reject) => {
          const uniqueImageName = `${new Date().getTime()}_${image.name}`; // Générer un nom unique
          const storageRef = ref(storage, "images/" + uniqueImageName);
          const metadata = { contentType: image.type };

          const uploadTask = uploadBytesResumable(storageRef, image, metadata);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done for ${image.name}`);
            },
            (error) => {
              console.error(`Error uploading image ${image.name}:`, error);
              reject(error);
            },
            () => {
              // Récupérer l'URL après téléchargement
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              });
            }
          );
        });
      });

      // Attendre que toutes les images soient uploadées
      const imageURLs = await Promise.all(imageUploadPromises);

      // Ajouter le document avec les URLs des images à Firestore
      await addDoc(colRef, {
        ...article,
        images: imageURLs, // Stocker les URLs des images dans le document
        createdAt: new Date().getTime(),
      });

      setLoading(false);
      redirect()
    } catch (err) {
      setLoading(false);
      console.log("Error adding post:", err);
    }
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Ajouter un article " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Titre"
                required
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Rubrique</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={article.rubrique}
                name='rubrique'
                label="Rubrique"
                onChange={handleChange}
                fullWidth
                required
              >
                <MenuItem value='Actualité'>Actualité</MenuItem>
                <MenuItem value='Annonce'>Annonce</MenuItem>
                <MenuItem value='Buzz'>Buzz</MenuItem>
                <MenuItem value='Culture'>Culture</MenuItem>
                <MenuItem value='Emploi'>Economie</MenuItem>
                <MenuItem value='Emploi'>Emploi</MenuItem>
                <MenuItem value='Gospel'>Gospel</MenuItem>
                <MenuItem value='Musique'>Musique</MenuItem>
                <MenuItem value='Politique'>Politique</MenuItem>
                <MenuItem value='Société'>Société</MenuItem>
                <MenuItem value='Sport'>Sport</MenuItem>
              </Select>

            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="author-select-label">Auteur</InputLabel>
              <Select
                labelId="author-select-label"
                id="author-select-label"
                value={article.author}
                name='author'
                label="Auteur"
                onChange={handleChange}
                fullWidth
                required
              >
                {
                  authors.map(author => (
                    <MenuItem value={author}>{author}</MenuItem>
                  ))
                }
              </Select>

            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={article.content}
                onChange={handleEditorChange}
                style={{ height: 200 }}
              />
            </Grid>
            <Grid sx={{ marginTop: 5 }} item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="import"
                type="file"
                onChange={loadFile}
                multiple
              />
              <label htmlFor="import">
                <Button
                  startIcon={<CameraAlt />}
                  variant="outlined"
                  component="span"
                >
                  Importer des images
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }}>
                {previews.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    style={{ marginRight: "10px" }}
                    width={200}
                  />
                ))}
              </Typography>
            </Grid>
            <Grid item xs={4} md={8}></Grid>
            <Grid item md={4}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ color: "white", borderRadius: 10 }}
                disableElevation
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default AddArticle;
