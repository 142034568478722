import { Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import DashboardPageHeader from '../../components/includes/DashboardPageHeader'
import SearchBar from '../../components/includes/SearchBar'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTheme } from '@emotion/react'
import { db } from '../../firebase'
import Videos from '../../components/includes/Videos'


const AllVideos = () => {
    const [articles, setArticles] = useState(null);
    const [data, setData] = useState(null);
    const [search, setSearch] = useState(null);
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const redirect = () => navigate("/videos");

    useEffect(() => {
        if (!db) return; // Attendre que Firestore soit initialisé
        const colRef = collection(db, "videos");
        const q = query(
            colRef,
            //where("status", "==", "validated"),
            orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
                setData(content);
            }
        });

        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);

    const searchFilter = (text) => {
        const newData =
            articles &&
            articles.filter((item) => {
                const itemData = item.title
                    ? item.title.toUpperCase()
                    : "".toUpperCase();
                const textData = text.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
        setSearch(text);
        setData(newData);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DashboardPageHeader title={"Videos"} />
                </Grid>
                <Grid item xs={12}>
                    <SearchBar
                        searchFilter={searchFilter}
                        placeholder={"Rechercher une vidéo..."}
                    />
                </Grid>

                {
                    data && data.map(video => (
                        <Fragment>
                            <Grid item xs={12} md={3}>
                                <Videos youtubeVideoID={video.youtubeVideoID} />
                            </Grid>
                        </Fragment>
                    ))
                }
            </Grid>
        </div>
    )
}

export default AllVideos