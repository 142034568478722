import { Button, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from 'react-redux'
import { addVideo } from "../../redux/actions/videoActions";
import Videos from "../../components/includes/Videos";
import { authors } from "../../data";

const mapState = ({ video }) => ({
    loading: video.loading
})

const AddVideo = () => {
    const navigate = useNavigate();
    const [article, setArticle] = useState({});
    const dispatch = useDispatch()

    const { loading } = useSelector(mapState);

    const handleEditorChange = (value) => {
        setArticle({ ...article, content: value });
    };

    const handleChange = (e) =>
        setArticle({ ...article, [e.target.name]: e.target.value });

    const redirect = () => navigate("/videos");

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(addVideo(article, redirect))
    };

    return (
        <main>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DashboardPageHeader title="Ajouter une video " />
                </Grid>
            </Grid>
            <Paper elevation={0} sx={{ p: 3 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Titre"
                                required
                                type="text"
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="author-select-label">Type</InputLabel>
                            <Select
                                labelId="author-select-label"
                                id="author-select-label"
                                value={article.type}
                                name='type'
                                label="Type de vidéo"
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                <MenuItem value='Interview'>Interview</MenuItem>
                                <MenuItem value='Reportage'>Reportage</MenuItem>
                                <MenuItem value='Reel'>Réel/video courte</MenuItem>
                                <MenuItem value='Emission'>Emission</MenuItem>
                            </Select>

                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="demo-simple-select-label">Rubrique</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={article.rubrique}
                                name='rubrique'
                                label="Rubrique"
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                <MenuItem value='Actualité'>Actualité</MenuItem>
                                <MenuItem value='Annonce'>Annonce</MenuItem>
                                <MenuItem value='Buzz'>Buzz</MenuItem>
                                <MenuItem value='Culture'>Culture</MenuItem>
                                <MenuItem value='Emploi'>Emploi</MenuItem>
                                <MenuItem value='Gospel'>Gospel</MenuItem>
                                <MenuItem value='Musique'>Musique</MenuItem>
                                <MenuItem value='Politique'>Politique</MenuItem>
                                <MenuItem value='Société'>Société</MenuItem>
                                <MenuItem value='Sport'>Sport</MenuItem>
                            </Select>

                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="author-select-label">Auteur</InputLabel>
                            <Select
                                labelId="author-select-label"
                                id="author-select-label"
                                value={article.author}
                                name='author'
                                label="Auteur"
                                onChange={handleChange}
                                fullWidth
                                required
                            >
                                {
                                    authors.map(author => (
                                        <MenuItem value={author}>{author}</MenuItem>
                                    ))
                                }
                            </Select>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="youtubeVideoID"
                                label="ID de la vidéo"
                                type="text"
                                placeholder="ID vidéo YouTube"
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactQuill
                                theme="snow"
                                value={article.content}
                                onChange={handleEditorChange}
                                style={{ height: 200 }}
                            />
                        </Grid>
                        <Grid sx={{ mt: 5 }} item xs={12}>
                            {
                                article.youtubeVideoID && <Videos youtubeVideoID={article.youtubeVideoID} />
                            }
                        </Grid>
                        <Grid item xs={4} md={8}></Grid>
                        <Grid sx={{ mt: 3 }} item md={4}>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                fullWidth
                                type="submit"
                                sx={{ color: "white", borderRadius: 10 }}
                                disableElevation
                            >
                                Valider
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </main>
    );
};

export default AddVideo;
