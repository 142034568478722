import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Grid } from "@mui/material";
import SearchBar from "../../components/includes/SearchBar";
import { useEffect } from "react";
import { useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import parse from "html-react-parser";
import { deleteArticle } from "../../redux/actions/blogActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SharedButtons from "../../components/includes/SharedButtons";

const Blog = () => {
  const [articles, setArticles] = useState(null);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const redirect = () => navigate("/articles");

  useEffect(() => {
    if (!db) return; // Attendre que Firestore soit initialisé
    const colRef = collection(db, "articles");
    const q = query(
      colRef,
      //where("status", "==", "validated"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setArticles(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  const searchFilter = (text) => {
    const newData =
      articles &&
      articles.filter((item) => {
        const itemData = item.content
          ? item.content.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  const handleDelete = (articleID) => {
    const isConfirmed = window.confirm("Êtes-vous sùr de vouloir supprimer cet article?");

    if (isConfirmed) {
      dispatch(deleteArticle(articleID, redirect))
    }
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title={"Articles"} />
        </Grid>
        <Grid item xs={12}>
          <SearchBar
            searchFilter={searchFilter}
            placeholder={"Rechercher un article..."}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ p: 3 }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        direction={"row"}
        spacing={1}
      >
        <Grid sx={{ p: 3 }} item xs={12}>
          <Typography variant="h6">
            {articles && articles.length} article(s) disponible(s)
          </Typography>
        </Grid>
        {data &&
          data.map((article) => (
            <Grid item xs={12} md={4}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  alt="Formation image"
                  height="200"
                  src={article.images ? article.images[0] : article.image}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {article.title}
                  </Typography>
                  <Typography sx={{ backgroundColor: theme.palette.primary.main, color: 'white', width: '30%', textAlign: 'center', borderRadius: 4 }}>{article.rubrique}</Typography>
                  <Box sx={{ my: 2 }}>
                    <SharedButtons url={`
                            ${article.title}\n
                            https://voiceofcongo.net/articles/${article.id}`}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Button color="primary" sx={{ borderRadius: 10 }}>
                    {moment(article.createdAt).fromNow()}
                  </Button>
                  <IconButton
                    component={"a"}
                    href={"/articles/" + article.id}
                    variant="default"
                  >

                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    component={"a"}
                    href={"/articles/modifier/" + article.id}
                    variant="default"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(article.id)} variant="default">
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </main>
  );
};

export default Blog;
