import Blog from "./views/blog";
import AddArticle from "./views/blog/Add";
import BlogDetails from "./views/blog/Details";
import EditArticle from "./views/blog/Edit";
import Events from "./views/events";
import AddEvent from "./views/events/Add";
import AllVideos from "./views/videos";
import AddVideo from "./views/videos/Add";
import Offers from "./views/offer";
import AddOffer from "./views/offer/Add";
import OfferDetails from "./views/offer/Details";
import EditOffer from "./views/offer/Edit";
import Applicants from "./views/applicant";

const routes = [
  {
    name: "Article",
    path: "/articles",
    component: <Blog />,
  },
  {
    name: "Article Details",
    path: "/articles/:articleID",
    component: <BlogDetails />,
  },
  {
    name: "Add Article",
    path: "/ajouter-un-article",
    component: <AddArticle />,
  },
  {
    name: "Edit Article",
    path: "/articles/modifier/:articleID",
    component: <EditArticle />,
  },
  {
    name: "Events",
    path: "/evenements",
    component: <Events />,
  },
  {
    name: "Event Details",
    path: "/evenements/:eventID",
    component: <BlogDetails />,
  },
  {
    name: "Add Event",
    path: "/ajouter-un-evenement",
    component: <AddEvent />,
  },
  {
    name: "Edit Event",
    path: "/evenements/modifier/:eventID",
    component: <EditArticle />,
  },

  {
    name: "Videos",
    path: "/videos",
    component: <AllVideos />,
  },
  {
    name: "Edit Video",
    path: "/videos/modifier/:videoID",
    component: <BlogDetails />,
  },
  {
    name: "Add Video",
    path: "/ajouter-une-video",
    component: <AddVideo />,
  },
  {
    name: "Offer",
    path: "/offres",
    component: <Offers />,
  },
  {
    name: "Offer Details ",
    path: "/offres/:offerID",
    component: <OfferDetails />,
  },
  {
    name: "Edit Offer ",
    path: "/offres/modifier/:offerID",
    component: <EditOffer />,
  },
  {
    name: "Add Offer",
    path: "/ajouter-une-offre",
    component: <AddOffer />,
  },
  {
    name: "Applicant",
    path: "/candidats",
    component: <Applicants />,
  },
];

export default routes;
