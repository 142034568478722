import React from "react";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ArticleIcon from "@mui/icons-material/Article";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import PublishIcon from "@mui/icons-material/Publish";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PeopleIcon from "@mui/icons-material/People";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

const iconProps = {
  color: "primary",
};

export const drawerItems = [
  {
    text: "Articles",
    icon: <ArticleIcon {...iconProps} />,
    path: "/articles",
  },
  {
    text: "Offres d'emploi",
    icon: <MarkunreadMailboxIcon {...iconProps} />,
    path: "/offres",
  },
  {
    text: "Vidéos",
    icon: <VideoLibraryIcon {...iconProps} />,
    path: "/videos",
  },
  {
    text: "Candidats",
    icon: <PeopleIcon {...iconProps} />,
    path: "/candidats",
  },
];

export const secondaryListItems = [
  {
    text: "Publier un article",
    icon: <PublishIcon {...iconProps} />,
    path: "/ajouter-un-article",
  },
  {
    text: "Publier une offre",
    icon: <NoteAddIcon {...iconProps} />,
    path: "/ajouter-une-offre",
  },
  {
    text: "Ajouter une video",
    icon: <VideoCallIcon {...iconProps} />,
    path: "/ajouter-une-video",
  },

];
