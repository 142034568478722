import { LoadingButton } from "@mui/lab";
import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/authActions";
import Logo from "../icons/Logo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";

const mapState = ({ auth }) => ({
  loading: auth.loading,
});

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const { loading } = useSelector(mapState);
  const [data, setData] = useState([]);

  useEffect(() => {
    const docRef = doc(db, "admin", "Mb8Ld9dIAhXdv9oouEhtNAko8up1");

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.data()) {
          setData({ ...snapshot.data(), id: snapshot.id });
        }
      },
      (err) => {
        console.log(err.message);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  console.log(data)

  const handleChange = (e) =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.email == credentials.email) {
      dispatch(login(credentials, () => navigate("/articles")));
    } else {
      toast.error("Identifiant ou mot de passe incorrect");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Logo width={150} />
        </Grid>
        <Grid sx={{ marginTop: -2 }} item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ fontWeight: "600" }}
            variant="h4"
          >
            Administration
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="E-mail"
            required
            fullWidth
            variant="standard"
            type="email"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="password"
            label="Mot de passe"
            fullWidth
            required
            variant="standard"
            type="password"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ color: "#fff", borderRadius: 10 }}
            color="primary"
            fullWidth
          >
            Connexion
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginForm;
